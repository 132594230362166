import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue'
            ),
    },
    {
        path: '/terms-conditions',
        name: 'TermsAndConditions',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/TermsAndConditions.vue'
            ),
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router
