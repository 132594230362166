<template>
  <div class="home">
    <img height="128" alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to OneAgent App" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
  },
}
</script>
